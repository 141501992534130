import '@/scss/main.scss'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AuthService from '@/services/auth-service'
import Axios from 'axios'
import * as dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

import Buefy from 'buefy'
// import 'buefy/dist/buefy.css'
import messageHub from '@/services/messageHub'

import { registerLicense } from '@syncfusion/ej2-base';
registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF5cXmpCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXhccnVRR2RdUkN3WEA=');

import { FileManagerPlugin } from "@syncfusion/ej2-vue-filemanager";
Vue.use(FileManagerPlugin);

import vueKanban from 'vue-kanban'
Vue.use(vueKanban)

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fab, fas, far);

import ToastPlugin from '@/plugins/toastPlugin.js';
Vue.use(ToastPlugin);

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.prototype.$dayjs = dayjs
Vue.prototype.$messageHub = messageHub;
//Vue.prototype.$msal = msalInstance;


Vue.config.productionTip = false

Axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
Axios.interceptors.request.use(function (config) {

    if (!config.url.includes('Auth/')) {
        var user = store.state.user;
        var token = user !== null && user.token !== undefined ? user.token : "";
        config.headers.Authorization = token ? `Bearer ${token}` : ''
    }

    return config
}, function (error) {
    return Promise.reject(error)
})

// Response interceptor for API calls
Axios.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    
    if (error.response) {
        // Request made and server responded
        var isAuthorisationError = error.response.status === 403 || error.response.status === 401;

        if (isAuthorisationError && !originalRequest._retry) {
            originalRequest._retry = true;

            await AuthService.refreshAccessToken();
            
            var user = store.state.user;
            var token = user !== null && user.token !== undefined ? user.token : "";
           
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

            if (originalRequest.data && typeof originalRequest.data === 'string')
                originalRequest.data = JSON.parse(originalRequest.data); //otherwise the content type is set as text not json on second attempt

            return Axios(originalRequest);
        }

    } else if (error.request) {
        // The request was made but no response was received
    } else {
        // Something happened in setting up the request that triggered an Error
    }

    return Promise.reject(error);
});

Vue.component('fa-icon', FontAwesomeIcon)
Vue.use(Buefy, {
    defaultIconComponent: 'fa-icon',
    defaultIconPack: 'fas',
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
